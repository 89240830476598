<template>
  <div>
    <section class="mb-5">
      <div class="ui-type-display-lg mb-3">I miei ordini</div>
      <CCard>
        <CCardBody>
          <CRow>
            <CCol col="auto" md="3">
              <div class="ui-type-caption text-uppercase">Ordine</div>
              <div class="ui-type-display-sm">#123456</div>
              <div class="badge badge-info">in approvazione</div>
            </CCol>
            <CCol col="auto" :lg="{ order: '2' }" class="ml-auto">
              <CButton color="primary" variant="outline" class="text-uppercase"
                >Modifica ordine</CButton
              >
            </CCol>
            <CCol col="12" lg>
              <CRow>
                <CCol>
                  <div class="mb-4">
                    <div class="ui-type-caption text-uppercase">
                      Data ordine
                    </div>
                    <div class="ui-type-body font-weight-bold">
                      12/06/2021 · 11:22
                    </div>
                  </div>
                  <div class="mb-4">
                    <div class="ui-type-caption text-uppercase">
                      Data consegna prevista
                    </div>
                    <div class="ui-type-body font-weight-bold">17/06/2021</div>
                  </div>
                </CCol>
                <CCol>
                  <div class="mb-4">
                    <div class="ui-type-caption text-uppercase">
                      Totale spesa
                    </div>
                    <div class="ui-type-body font-weight-bold">9.57 CHF</div>
                  </div>
                  <div class="mb-4">
                    <div class="ui-type-caption text-uppercase">Consegna</div>
                    <div class="ui-type-body font-weight-bold">
                      La Posta - consegna a domicilio
                    </div>
                  </div>
                </CCol>
                <CCol>
                  <div class="mb-4">
                    <div class="ui-type-caption text-uppercase">Mercato</div>
                    <div class="ui-type-body font-weight-bold">Terranostra</div>
                  </div>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <div class="table-responsive">
            <table class="table align-middle">
              <thead>
                <tr>
                  <th width="62"></th>
                  <th>NOME</th>
                  <th class="text-right">QUANTITÀ</th>
                  <th></th>
                  <th class="text-right">TOTALE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <img
                      src="https://picsum.photos/36"
                      class="border rounded"
                    />
                  </td>
                  <td>
                    <a class="ui-type-body font-weight-bold" href="#0"
                      >Pane a fette</a
                    >
                    <div class="ui-type-body">Confezione 15 pezzi</div>
                  </td>
                  <td class="text-right">
                    <div class="ui-type-body font-weight-bold">
                      <del class="text-gray-400 mr-2">2 pz</del>
                      1 pz
                    </div>
                  </td>
                  <td>
                    <div class="badge badge-warning">modifica</div>
                  </td>
                  <td class="text-right">
                    <div class="ui-type-heading">
                      <small
                        ><del class="text-gray-400 mr-2">1.50 CHF</del></small
                      >
                      3.00 CHF
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="ui-cell-disabled">
                    <img
                      src="https://picsum.photos/36"
                      class="border rounded"
                    />
                  </td>
                  <td class="ui-cell-disabled">
                    <a class="ui-type-body font-weight-bold" href="#0"
                      >Pane a fette</a
                    >
                    <div class="ui-type-body">Confezione 15 pezzi</div>
                  </td>
                  <td class="ui-cell-disabled text-right">
                    <div class="ui-type-body font-weight-bold">
                      <del class="mr-2">2 pz</del>
                    </div>
                  </td>
                  <td>
                    <div class="badge badge-danger">annullato</div>
                  </td>
                  <td class="ui-cell-disabled text-right">
                    <div class="ui-type-heading">
                      <del>3.00 CHF</del>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src="https://picsum.photos/36"
                      class="border rounded"
                    />
                  </td>
                  <td>
                    <a class="ui-type-body font-weight-bold" href="#0"
                      >Pane a fette</a
                    >
                    <div class="ui-type-body">Confezione 15 pezzi</div>
                  </td>
                  <td class="text-right">
                    <div class="ui-type-body font-weight-bold">1 pz</div>
                  </td>
                  <td>
                    <div class="badge badge-success">consegnato</div>
                  </td>
                  <td class="text-right">
                    <div class="ui-type-heading">3.00 CHF</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src="https://picsum.photos/36"
                      class="border rounded"
                    />
                  </td>
                  <td>
                    <a class="ui-type-body font-weight-bold" href="#0"
                      >Pane a fette</a
                    >
                    <div class="ui-type-body">Confezione 15 pezzi</div>
                  </td>
                  <td class="text-right">
                    <div class="ui-type-body font-weight-bold">1 pz</div>
                  </td>
                  <td></td>
                  <td class="text-right">
                    <div class="ui-type-heading">3.00 CHF</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CCardBody>
      </CCard>
    </section>

    <section class="mb-5">
      <h1>Scheda prodotto</h1>
      <CRow class="align-items-center mb-3">
        <CCol col="auto" class="mt-2 mb-1 my-sm-0">
          <CButton color="outline-primary" @click="onCancel()">
            <font-awesome-icon icon="arrow-left" />
          </CButton>
        </CCol>
        <CCol sm>
          <strong class="text-gray-700"
            >Terranostra / Frutta e verdura / Verdura di stagione</strong
          >
          <div class="ui-type-display-lg">Prezzemolo liscio</div>
        </CCol>
        <CCol col="auto" class="ml-auto text-right">
          <CButton color="primary">
            <font-awesome-icon icon="shopping-cart" class="mr-1" />
            0 PRODOTTI
          </CButton>
          <div class="ui-type-caption text-gray-600">
            Ordine minimo <strong>15€</strong>
          </div>
        </CCol>
      </CRow>

      <CCard>
        <CCardBody>
          <CRow>
            <CCol>
              <CRow>
                <CCol :lg="{ size: '7', order: '2' }" class="mb-3">
                  <div class="ui-type-caption text-uppercase text-gray-600">
                    Fondazione Orchidea
                  </div>
                  <div class="ui-type-display-sm mb-3">Prezzemolo liscio</div>
                  <CSelect
                    label="Seleziona variante"
                    addInputClasses="d-block w-auto"
                    :options="[
                      'Mazzetto, circa 100g',
                      'Mazzetto, circa 500g',
                      'Mazzetto, circa 1kg',
                      'Mazzetto, circa 10kg',
                    ]"
                    custom
                  />
                  <div class="ui-type-display-md font-weight-bold">
                    4.00 <sup>CHF</sup>
                  </div>
                  <div class="ui-type-subheading text-gray-600">
                    0.57 CHF/kg
                  </div>
                  <hr />
                  <table class="w-100">
                    <tr>
                      <th>Adatto a diete</th>
                      <td>Vegana, Vegetariana</td>
                    </tr>
                    <tr>
                      <th>Certificazioni</th>
                      <td>Bio Swiss, Alpina vera, Bio Ticino</td>
                    </tr>
                    <tr>
                      <th>Origine</th>
                      <td>BProduzione propria</td>
                    </tr>
                    <tr>
                      <th>Durata garantita</th>
                      <td>2 giorni</td>
                    </tr>
                  </table>
                </CCol>
                <CCol lg="5" class="mb-3">
                  <flickity
                    ref="flickity"
                    :options="flickityProduct"
                    class="flickity-product mb-2"
                  >
                    <figure class="flickity-figure">
                      <img
                        class="flickity-img"
                        src="https://picsum.photos/id/701/1200/800"
                        width="1200"
                        height="800"
                        alt=""
                      />
                    </figure>
                    <figure class="flickity-figure">
                      <img
                        class="flickity-img"
                        src="https://picsum.photos/id/702/800/800"
                        width="800"
                        height="800"
                        alt=""
                      />
                    </figure>
                    <figure class="flickity-figure">
                      <img
                        class="flickity-img"
                        src="https://picsum.photos/id/703/800/1200"
                        width="800"
                        height="1200"
                        alt=""
                      />
                    </figure>
                    <figure class="flickity-figure">
                      <img
                        class="flickity-img"
                        src="https://picsum.photos/id/704/1200/800"
                        width="1200"
                        height="800"
                        alt=""
                      />
                    </figure>
                  </flickity>
                  <flickity
                    ref="flickity"
                    :options="flickityProductAsNavFor"
                    class="flickity-product-asnavfor"
                  >
                    <figure class="flickity-figure mr-2">
                      <img
                        class="flickity-img"
                        src="https://picsum.photos/id/701/68/68"
                        width="68"
                        height="68"
                        alt=""
                      />
                    </figure>
                    <figure class="flickity-figure mr-2">
                      <img
                        class="flickity-img"
                        src="https://picsum.photos/id/702/68/68"
                        width="68"
                        height="68"
                        alt=""
                      />
                    </figure>
                    <figure class="flickity-figure mr-2">
                      <img
                        class="flickity-img"
                        src="https://picsum.photos/id/703/68/68"
                        width="68"
                        height="68"
                        alt=""
                      />
                    </figure>
                    <figure class="flickity-figure mr-2">
                      <img
                        class="flickity-img"
                        src="https://picsum.photos/id/704/68/68"
                        width="68"
                        height="68"
                        alt=""
                      />
                    </figure>
                  </flickity>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <div class="font-weight-bold mb-2">Descrizione</div>
                  <div class="ui-type-body">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                    sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                    ipsum dolor sit amet,
                  </div>
                </CCol>
              </CRow>
              <hr class="my-4" />
              <CRow>
                <CCol md="3">
                  <figure class="mb-0">
                    <img
                      src="https://picsum.photos/1200/800"
                      alt=""
                      class="img-fluid"
                    />
                  </figure>
                </CCol>
                <CCol>
                  <div class="ui-type-caption text-uppercase text-gray-600">
                    Produttore
                  </div>
                  <div class="ui-type-display-sm">Fondazione Orchidea</div>
                  <div class="ui-type-body mb-2">
                    <a href="#0">Via Campagna 14, 6595 Riazzino</a>
                  </div>
                  <div class="ui-type-body">
                    L’Orchidea è un’azienda agricola che ha lo scopo di
                    promuovere l’integrazione sociale e professionale di persone
                    a beneficio di una prestazione AI. Operiamo in stretta
                    collaborazione con il DSS. La struttura dà stabilmente
                    lavoro a 25 collaboratori in attività protetta, coadiuvati
                    da un ingeniere agronomo, due floricoltori, un maestro
                    socio-professionale e due educatori. Inoltre dà la
                    possibilità, laddove ne esistano i presupposti, di svolgere
                    un tirocinio triennale per il conseguimento dell’AFC quale
                    giardiniere floricoltore, oppure una formazione biennale con
                    diploma CFC. Particolare attenzione viene posta anche
                    all’inserimento o reinserimento in ditte che operano nel
                    ramo.
                  </div>
                </CCol>
              </CRow>
            </CCol>
            <CCol lg="auto">
              <CCard class="mb-0" style="max-width: 12rem">
                <CCardBody>
                  <div class="text-primary mb-2">Venduto da Terranostra</div>
                  <div>
                    Ordina entro <strong class="text-secondary">12 ore</strong>
                  </div>
                  <div class="mb-2">
                    Consegneremo <strong>giovedì 12/05</strong>
                  </div>
                  <div class="mb-2">
                    oppure ritiralo
                    <strong>mercoledì 11/05 dalle 09:30</strong>
                    <a href="#0">nei nostri punti di ritiro</a>
                  </div>
                  <CInput label="Quantità" append="pezzi" />
                  <CButton color="primary" block="true">
                    <font-awesome-icon icon="shopping-cart" class="mr-1" />
                    AGGIUNGI
                  </CButton>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </section>

    <section class="mb-5">
      <h1>Mercato</h1>
      <CCard>
        <CCardBody>
          <CRow class="justify-content-between align-items-center mb-3">
            <CCol>
              <div class="d-flex align-items-center">
                <figure class="m-0 mr-3">
                  <img
                    src="https://picsum.photos/64"
                    alt=""
                    class="border rounded-circle"
                  />
                </figure>
                <div>
                  <div class="ui-type-caption text-uppercase">
                    Selezione del mercato
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="d-inline-block ui-type-display-lg mr-3"
                      >Valposchiavo</span
                    >
                    <span class="badge badge-success"
                      >Servizio consegna attivo</span
                    >
                  </div>
                  <div>
                    Prodotti esclusivi che provengono interamente dalla
                    Valposchiavo
                  </div>
                </div>
              </div>
            </CCol>
            <CCol col="auto" class="ml-auto text-right">
              <div class="mb-2">Vendita agli invitati, come te</div>
              <CButton color="primary" size="sm">VEDI TUTTI I PRODOTTI</CButton>
            </CCol>
          </CRow>
          <flickity
            ref="flickity"
            :options="flickityProducts"
            class="flickity-products"
          >
            <CCol class="flickity-col"
              ><div class="ui-card-product">
                <figure class="ui-card-product__figure">
                  <img
                    class="ui-card-product__img"
                    src="https://picsum.photos/id/903/900/600"
                    width="900"
                    height="600"
                    alt=""
                  />
                </figure>
                <div class="ui-card-product__company">Terrani Carni</div>
                <div class="ui-card-product__title">
                  Carne macinata per bolognese manzo
                </div>
                <div class="ui-card-product__subtitle"></div>
                <div class="ui-card-product__footer">
                  <div class="ui-card-product__info">minimo 1 kg</div>
                  <div class="ui-card-product__price">
                    0.57 <sup>CHF</sup>/kg
                  </div>
                </div>
              </div></CCol
            >
            <CCol class="flickity-col"
              ><div class="ui-card-product">
                <figure class="ui-card-product__figure">
                  <img
                    class="ui-card-product__img"
                    src="https://picsum.photos/id/903/900/600"
                    width="900"
                    height="600"
                    alt=""
                  />
                </figure>
                <div class="ui-card-product__company">Terrani Carni</div>
                <div class="ui-card-product__title">
                  Carne macinata per bolognese manzo
                </div>
                <div class="ui-card-product__subtitle"></div>
                <div class="ui-card-product__footer">
                  <div class="ui-card-product__info">minimo 1 kg</div>
                  <div class="ui-card-product__price">
                    0.57 <sup>CHF</sup>/kg
                  </div>
                </div>
              </div></CCol
            >
            <CCol class="flickity-col"
              ><div class="ui-card-product">
                <figure class="ui-card-product__figure">
                  <img
                    class="ui-card-product__img"
                    src="https://picsum.photos/id/903/900/600"
                    width="900"
                    height="600"
                    alt=""
                  />
                </figure>
                <div class="ui-card-product__company">Terrani Carni</div>
                <div class="ui-card-product__title">
                  Carne macinata per bolognese manzo
                </div>
                <div class="ui-card-product__subtitle"></div>
                <div class="ui-card-product__footer">
                  <div class="ui-card-product__info">minimo 1 kg</div>
                  <div class="ui-card-product__price">
                    0.57 <sup>CHF</sup>/kg
                  </div>
                </div>
              </div></CCol
            >
            <CCol class="flickity-col"
              ><div class="ui-card-product">
                <figure class="ui-card-product__figure">
                  <img
                    class="ui-card-product__img"
                    src="https://picsum.photos/id/903/900/600"
                    width="900"
                    height="600"
                    alt=""
                  />
                </figure>
                <div class="ui-card-product__company">Terrani Carni</div>
                <div class="ui-card-product__title">
                  Carne macinata per bolognese manzo
                </div>
                <div class="ui-card-product__subtitle"></div>
                <div class="ui-card-product__footer">
                  <div class="ui-card-product__info">minimo 1 kg</div>
                  <div class="ui-card-product__price">
                    0.57 <sup>CHF</sup>/kg
                  </div>
                </div>
              </div></CCol
            >
            <CCol class="flickity-col"
              ><div class="ui-card-product">
                <figure class="ui-card-product__figure">
                  <img
                    class="ui-card-product__img"
                    src="https://picsum.photos/id/903/900/600"
                    width="900"
                    height="600"
                    alt=""
                  />
                </figure>
                <div class="ui-card-product__company">Terrani Carni</div>
                <div class="ui-card-product__title">
                  Carne macinata per bolognese manzo
                </div>
                <div class="ui-card-product__subtitle"></div>
                <div class="ui-card-product__footer">
                  <div class="ui-card-product__info">minimo 1 kg</div>
                  <div class="ui-card-product__price">
                    0.57 <sup>CHF</sup>/kg
                  </div>
                </div>
              </div></CCol
            >
            <CCol class="flickity-col"
              ><div class="ui-card-product">
                <figure class="ui-card-product__figure">
                  <img
                    class="ui-card-product__img"
                    src="https://picsum.photos/id/903/900/600"
                    width="900"
                    height="600"
                    alt=""
                  />
                </figure>
                <div class="ui-card-product__company">Terrani Carni</div>
                <div class="ui-card-product__title">
                  Carne macinata per bolognese manzo
                </div>
                <div class="ui-card-product__subtitle"></div>
                <div class="ui-card-product__footer">
                  <div class="ui-card-product__info">minimo 1 kg</div>
                  <div class="ui-card-product__price">
                    0.57 <sup>CHF</sup>/kg
                  </div>
                </div>
              </div></CCol
            >
            <CCol class="flickity-col"
              ><div class="ui-card-product">
                <figure class="ui-card-product__figure">
                  <img
                    class="ui-card-product__img"
                    src="https://picsum.photos/id/903/900/600"
                    width="900"
                    height="600"
                    alt=""
                  />
                </figure>
                <div class="ui-card-product__company">Terrani Carni</div>
                <div class="ui-card-product__title">
                  Carne macinata per bolognese manzo
                </div>
                <div class="ui-card-product__subtitle"></div>
                <div class="ui-card-product__footer">
                  <div class="ui-card-product__info">minimo 1 kg</div>
                  <div class="ui-card-product__price">
                    0.57 <sup>CHF</sup>/kg
                  </div>
                </div>
              </div></CCol
            >
            <CCol class="flickity-col"
              ><div class="ui-card-product">
                <figure class="ui-card-product__figure">
                  <img
                    class="ui-card-product__img"
                    src="https://picsum.photos/id/903/900/600"
                    width="900"
                    height="600"
                    alt=""
                  />
                </figure>
                <div class="ui-card-product__company">Terrani Carni</div>
                <div class="ui-card-product__title">
                  Carne macinata per bolognese manzo
                </div>
                <div class="ui-card-product__subtitle"></div>
                <div class="ui-card-product__footer">
                  <div class="ui-card-product__info">minimo 1 kg</div>
                  <div class="ui-card-product__price">
                    0.57 <sup>CHF</sup>/kg
                  </div>
                </div>
              </div></CCol
            >
            <CCol class="flickity-col"
              ><div class="ui-card-product">
                <figure class="ui-card-product__figure">
                  <img
                    class="ui-card-product__img"
                    src="https://picsum.photos/id/903/900/600"
                    width="900"
                    height="600"
                    alt=""
                  />
                </figure>
                <div class="ui-card-product__company">Terrani Carni</div>
                <div class="ui-card-product__title">
                  Carne macinata per bolognese manzo
                </div>
                <div class="ui-card-product__subtitle"></div>
                <div class="ui-card-product__footer">
                  <div class="ui-card-product__info">minimo 1 kg</div>
                  <div class="ui-card-product__price">
                    0.57 <sup>CHF</sup>/kg
                  </div>
                </div>
              </div></CCol
            >
            <CCol class="flickity-col"
              ><div class="ui-card-product">
                <figure class="ui-card-product__figure">
                  <img
                    class="ui-card-product__img"
                    src="https://picsum.photos/id/903/900/600"
                    width="900"
                    height="600"
                    alt=""
                  />
                </figure>
                <div class="ui-card-product__company">Terrani Carni</div>
                <div class="ui-card-product__title">
                  Carne macinata per bolognese manzo
                </div>
                <div class="ui-card-product__subtitle"></div>
                <div class="ui-card-product__footer">
                  <div class="ui-card-product__info">minimo 1 kg</div>
                  <div class="ui-card-product__price">
                    0.57 <sup>CHF</sup>/kg
                  </div>
                </div>
              </div></CCol
            >
            <CCol class="flickity-col"
              ><div class="ui-card-product">
                <figure class="ui-card-product__figure">
                  <img
                    class="ui-card-product__img"
                    src="https://picsum.photos/id/903/900/600"
                    width="900"
                    height="600"
                    alt=""
                  />
                </figure>
                <div class="ui-card-product__company">Terrani Carni</div>
                <div class="ui-card-product__title">
                  Carne macinata per bolognese manzo
                </div>
                <div class="ui-card-product__subtitle"></div>
                <div class="ui-card-product__footer">
                  <div class="ui-card-product__info">minimo 1 kg</div>
                  <div class="ui-card-product__price">
                    0.57 <sup>CHF</sup>/kg
                  </div>
                </div>
              </div></CCol
            >
          </flickity>
        </CCardBody>
      </CCard>
    </section>

    <section class="mb-5">
      <CRow class="align-items-center mb-3">
        <CCol col="auto" class="mt-2 mb-1 my-sm-0">
          <CButton color="outline-primary" @click="onCancel()">
            <font-awesome-icon icon="arrow-left" />
          </CButton>
        </CCol>
        <CCol sm>
          <div class="ui-type-display-lg">Terranostra</div>
          <div>
            Distribuzione di prodotti locali in Canton Ticino, Svizzera.
            <a href="#0">Scopri di più</a>
          </div>
          <div>
            Ordina entro <strong>12 ore</strong> | Consegneremo
            <strong>giovedì 12/05</strong> | Ritiralo
            <strong>giovedì 11/05 dalle 09:30</strong>
            <a href="#0">nei nostri punti di ritiro</a>
          </div>
        </CCol>
        <CCol col="auto" class="ml-auto text-right">
          <CButton color="primary">
            <font-awesome-icon icon="shopping-cart" class="mr-1" />
            0 PRODOTTI
          </CButton>
          <div class="ui-type-caption text-gray-600">
            Ordine minimo <strong>15€</strong>
          </div>
        </CCol>
      </CRow>

      <CCard>
        <CCardBody>
          <CRow class="align-items-end">
            <CCol md="5">
              <CInput label="Cerca prodotti" placeholder="es. Fagioli borlotti">
                <template #prepend-content>
                  <font-awesome-icon icon="search" />
                </template>
              </CInput>
            </CCol>
            <CCol col="auto">
              <CSelect
                label="Produttore"
                :options="[
                  'Tutti',
                  'Produttore 1',
                  'Produttore 2',
                  'Produttore 3',
                ]"
                custom
              />
            </CCol>
            <CCol col="auto" class="ml-auto">
              <CButtonGroup class="mb-3">
                <CButton color="primary">
                  <font-awesome-icon icon="th" />
                </CButton>
                <CButton color="primary" variant="outline">
                  <font-awesome-icon icon="list" />
                </CButton>
              </CButtonGroup>
            </CCol>
          </CRow>

          <ul class="nav nav-tabs mb-3">
            <li class="nav-item"><div class="nav-link">Tutti</div></li>
            <li class="nav-item">
              <div class="nav-link active">Frutta e verdura</div>
            </li>
            <li class="nav-item">
              <div class="nav-link">Uova e latticini</div>
            </li>
            <li class="nav-item">
              <div class="nav-link">Pasta, cereali e farine</div>
            </li>
            <li class="nav-item">
              <div class="nav-link">Pane e sfornati</div>
            </li>
            <li class="nav-item"><div class="nav-link">Salumi</div></li>
            <li class="nav-item"><div class="nav-link">Carne e pesce</div></li>
            <li class="nav-item"><div class="nav-link">Gastronomia</div></li>
            <li class="nav-item">
              <div class="nav-link">Bevande e succhi</div>
            </li>
          </ul>

          <div class="ui-card-products">
            <div class="ui-card-product">
              <figure class="ui-card-product__figure">
                <img
                  class="ui-card-product__img"
                  src="https://picsum.photos/id/901/900/600"
                  alt=""
                />
              </figure>
              <div class="ui-card-product__company">
                Az. agricola sociale di CARITAS Ticino
              </div>
              <div class="ui-card-product__title">Biocassetta di stagione</div>
              <div class="ui-card-product__subtitle">
                Cassetta peso variabile
              </div>
              <div class="ui-card-product__footer">
                <div class="ui-card-product__info">0.57 CHF/kg</div>
                <div class="ui-card-product__price">13.00 <sup>CHF</sup></div>
              </div>
            </div>
            <div class="ui-card-product">
              <figure class="ui-card-product__figure">
                <img
                  class="ui-card-product__img"
                  src="https://picsum.photos/id/902/900/600"
                  alt=""
                />
              </figure>
              <div class="ui-card-product__company">Fattoria la Colombera</div>
              <div class="ui-card-product__title">Bio yogur frutta</div>
              <div class="ui-card-product__subtitle">3 varianti</div>
              <div class="ui-card-product__footer">
                <div class="ui-card-product__info"></div>
                <div class="ui-card-product__price">
                  11.00 <sup>CHF</sup>-13.00 <sup>CHF</sup>
                </div>
              </div>
            </div>
            <div class="ui-card-product">
              <figure class="ui-card-product__figure">
                <img
                  class="ui-card-product__img"
                  src="https://picsum.photos/id/903/900/600"
                  alt=""
                />
              </figure>
              <div class="ui-card-product__company">Terrani Carni</div>
              <div class="ui-card-product__title">
                Carne macinata per bolognese manzo
              </div>
              <div class="ui-card-product__subtitle"></div>
              <div class="ui-card-product__footer">
                <div class="ui-card-product__info">minimo 1 kg</div>
                <div class="ui-card-product__price">0.57 <sup>CHF</sup>/kg</div>
              </div>
            </div>
            <div class="ui-card-product">
              <figure class="ui-card-product__figure">
                <img
                  class="ui-card-product__img"
                  src="https://picsum.photos/id/904/900/600"
                  alt=""
                />
              </figure>
              <div class="ui-card-product__company">Profumo d’Aurora</div>
              <div class="ui-card-product__title">Sapone fichi e avena</div>
              <div class="ui-card-product__subtitle">Saponetta 100gr</div>
              <div class="ui-card-product__footer">
                <div class="ui-card-product__info">0.57 CHF/kg</div>
                <div class="ui-card-product__price">4.00 <sup>CHF</sup></div>
              </div>
            </div>
            <div class="ui-card-product">
              <figure class="ui-card-product__figure">
                <img
                  class="ui-card-product__img"
                  src="https://picsum.photos/id/905/900/600"
                  alt=""
                />
              </figure>
              <div class="ui-card-product__company">
                Az. agricola sociale di CARITAS Ticino
              </div>
              <div class="ui-card-product__title">Biocassetta di stagione</div>
              <div class="ui-card-product__subtitle">
                Cassetta peso variabile
              </div>
              <div class="ui-card-product__footer">
                <div class="ui-card-product__info">0.57 CHF/kg</div>
                <div class="ui-card-product__price">13.00 <sup>CHF</sup></div>
              </div>
            </div>
            <div class="ui-card-product">
              <figure class="ui-card-product__figure">
                <img
                  class="ui-card-product__img"
                  src="https://picsum.photos/id/906/900/600"
                  alt=""
                />
              </figure>
              <div class="ui-card-product__company">Fattoria la Colombera</div>
              <div class="ui-card-product__title">Bio yogur frutta</div>
              <div class="ui-card-product__subtitle">3 varianti</div>
              <div class="ui-card-product__footer">
                <div class="ui-card-product__info"></div>
                <div class="ui-card-product__price">
                  11.00 <sup>CHF</sup>-13.00 <sup>CHF</sup>
                </div>
              </div>
            </div>
            <div class="ui-card-product">
              <figure class="ui-card-product__figure">
                <img
                  class="ui-card-product__img"
                  src="https://picsum.photos/id/907/900/600"
                  alt=""
                />
              </figure>
              <div class="ui-card-product__company">Terrani Carni</div>
              <div class="ui-card-product__title">
                Carne macinata per bolognese manzo
              </div>
              <div class="ui-card-product__subtitle"></div>
              <div class="ui-card-product__footer">
                <div class="ui-card-product__info">minimo 1 kg</div>
                <div class="ui-card-product__price">0.57 <sup>CHF</sup>/kg</div>
              </div>
            </div>
            <div class="ui-card-product">
              <figure class="ui-card-product__figure">
                <img
                  class="ui-card-product__img"
                  src="https://picsum.photos/id/908/900/600"
                  alt=""
                />
              </figure>
              <div class="ui-card-product__company">Profumo d’Aurora</div>
              <div class="ui-card-product__title">Sapone fichi e avena</div>
              <div class="ui-card-product__subtitle">Saponetta 100gr</div>
              <div class="ui-card-product__footer">
                <div class="ui-card-product__info">0.57 CHF/kg</div>
                <div class="ui-card-product__price">4.00 <sup>CHF</sup></div>
              </div>
            </div>
            <div class="ui-card-product">
              <figure class="ui-card-product__figure">
                <img
                  class="ui-card-product__img"
                  src="https://picsum.photos/id/901/900/600"
                  alt=""
                />
              </figure>
              <div class="ui-card-product__company">
                Az. agricola sociale di CARITAS Ticino
              </div>
              <div class="ui-card-product__title">Biocassetta di stagione</div>
              <div class="ui-card-product__subtitle">
                Cassetta peso variabile
              </div>
              <div class="ui-card-product__footer">
                <div class="ui-card-product__info">0.57 CHF/kg</div>
                <div class="ui-card-product__price">13.00 <sup>CHF</sup></div>
              </div>
            </div>
            <div class="ui-card-product">
              <figure class="ui-card-product__figure">
                <img
                  class="ui-card-product__img"
                  src="https://picsum.photos/id/902/900/600"
                  alt=""
                />
              </figure>
              <div class="ui-card-product__company">Fattoria la Colombera</div>
              <div class="ui-card-product__title">Bio yogur frutta</div>
              <div class="ui-card-product__subtitle">3 varianti</div>
              <div class="ui-card-product__footer">
                <div class="ui-card-product__info"></div>
                <div class="ui-card-product__price">
                  11.00 <sup>CHF</sup>-13.00 <sup>CHF</sup>
                </div>
              </div>
            </div>
            <div class="ui-card-product">
              <figure class="ui-card-product__figure">
                <img
                  class="ui-card-product__img"
                  src="https://picsum.photos/id/903/900/600"
                  alt=""
                />
              </figure>
              <div class="ui-card-product__company">Terrani Carni</div>
              <div class="ui-card-product__title">
                Carne macinata per bolognese manzo
              </div>
              <div class="ui-card-product__subtitle"></div>
              <div class="ui-card-product__footer">
                <div class="ui-card-product__info">minimo 1 kg</div>
                <div class="ui-card-product__price">0.57 <sup>CHF</sup>/kg</div>
              </div>
            </div>
            <div class="ui-card-product">
              <figure class="ui-card-product__figure">
                <img
                  class="ui-card-product__img"
                  src="https://picsum.photos/id/904/900/600"
                  alt=""
                />
              </figure>
              <div class="ui-card-product__company">Profumo d’Aurora</div>
              <div class="ui-card-product__title">Sapone fichi e avena</div>
              <div class="ui-card-product__subtitle">Saponetta 100gr</div>
              <div class="ui-card-product__footer">
                <div class="ui-card-product__info">0.57 CHF/kg</div>
                <div class="ui-card-product__price">4.00 <sup>CHF</sup></div>
              </div>
            </div>
            <div class="ui-card-product">
              <figure class="ui-card-product__figure">
                <img
                  class="ui-card-product__img"
                  src="https://picsum.photos/id/905/900/600"
                  alt=""
                />
              </figure>
              <div class="ui-card-product__company">
                Az. agricola sociale di CARITAS Ticino
              </div>
              <div class="ui-card-product__title">Biocassetta di stagione</div>
              <div class="ui-card-product__subtitle">
                Cassetta peso variabile
              </div>
              <div class="ui-card-product__footer">
                <div class="ui-card-product__info">0.57 CHF/kg</div>
                <div class="ui-card-product__price">13.00 <sup>CHF</sup></div>
              </div>
            </div>
            <div class="ui-card-product">
              <figure class="ui-card-product__figure">
                <img
                  class="ui-card-product__img"
                  src="https://picsum.photos/id/906/900/600"
                  alt=""
                />
              </figure>
              <div class="ui-card-product__company">Fattoria la Colombera</div>
              <div class="ui-card-product__title">Bio yogur frutta</div>
              <div class="ui-card-product__subtitle">3 varianti</div>
              <div class="ui-card-product__footer">
                <div class="ui-card-product__info"></div>
                <div class="ui-card-product__price">
                  11.00 <sup>CHF</sup>-13.00 <sup>CHF</sup>
                </div>
              </div>
            </div>
            <div class="ui-card-product">
              <figure class="ui-card-product__figure">
                <img
                  class="ui-card-product__img"
                  src="https://picsum.photos/id/907/900/600"
                  alt=""
                />
              </figure>
              <div class="ui-card-product__company">Terrani Carni</div>
              <div class="ui-card-product__title">
                Carne macinata per bolognese manzo
              </div>
              <div class="ui-card-product__subtitle"></div>
              <div class="ui-card-product__footer">
                <div class="ui-card-product__info">minimo 1 kg</div>
                <div class="ui-card-product__price">0.57 <sup>CHF</sup>/kg</div>
              </div>
            </div>
            <div class="ui-card-product">
              <figure class="ui-card-product__figure">
                <img
                  class="ui-card-product__img"
                  src="https://picsum.photos/id/908/900/600"
                  alt=""
                />
              </figure>
              <div class="ui-card-product__company">Profumo d’Aurora</div>
              <div class="ui-card-product__title">Sapone fichi e avena</div>
              <div class="ui-card-product__subtitle">Saponetta 100gr</div>
              <div class="ui-card-product__footer">
                <div class="ui-card-product__info">0.57 CHF/kg</div>
                <div class="ui-card-product__price">4.00 <sup>CHF</sup></div>
              </div>
            </div>
            <div class="ui-card-product">
              <figure class="ui-card-product__figure">
                <img
                  class="ui-card-product__img"
                  src="https://picsum.photos/id/901/900/600"
                  alt=""
                />
              </figure>
              <div class="ui-card-product__company">
                Az. agricola sociale di CARITAS Ticino
              </div>
              <div class="ui-card-product__title">Biocassetta di stagione</div>
              <div class="ui-card-product__subtitle">
                Cassetta peso variabile
              </div>
              <div class="ui-card-product__footer">
                <div class="ui-card-product__info">0.57 CHF/kg</div>
                <div class="ui-card-product__price">13.00 <sup>CHF</sup></div>
              </div>
            </div>
            <div class="ui-card-product">
              <figure class="ui-card-product__figure">
                <img
                  class="ui-card-product__img"
                  src="https://picsum.photos/id/902/900/600"
                  alt=""
                />
              </figure>
              <div class="ui-card-product__company">Fattoria la Colombera</div>
              <div class="ui-card-product__title">Bio yogur frutta</div>
              <div class="ui-card-product__subtitle">3 varianti</div>
              <div class="ui-card-product__footer">
                <div class="ui-card-product__info"></div>
                <div class="ui-card-product__price">
                  11.00 <sup>CHF</sup>-13.00 <sup>CHF</sup>
                </div>
              </div>
            </div>
            <div class="ui-card-product">
              <figure class="ui-card-product__figure">
                <img
                  class="ui-card-product__img"
                  src="https://picsum.photos/id/903/900/600"
                  alt=""
                />
              </figure>
              <div class="ui-card-product__company">Terrani Carni</div>
              <div class="ui-card-product__title">
                Carne macinata per bolognese manzo
              </div>
              <div class="ui-card-product__subtitle"></div>
              <div class="ui-card-product__footer">
                <div class="ui-card-product__info">minimo 1 kg</div>
                <div class="ui-card-product__price">0.57 <sup>CHF</sup>/kg</div>
              </div>
            </div>
            <div class="ui-card-product">
              <figure class="ui-card-product__figure">
                <img
                  class="ui-card-product__img"
                  src="https://picsum.photos/id/904/900/600"
                  alt=""
                />
              </figure>
              <div class="ui-card-product__company">Profumo d’Aurora</div>
              <div class="ui-card-product__title">Sapone fichi e avena</div>
              <div class="ui-card-product__subtitle">Saponetta 100gr</div>
              <div class="ui-card-product__footer">
                <div class="ui-card-product__info">0.57 CHF/kg</div>
                <div class="ui-card-product__price">4.00 <sup>CHF</sup></div>
              </div>
            </div>
            <div class="ui-card-product">
              <figure class="ui-card-product__figure">
                <img
                  class="ui-card-product__img"
                  src="https://picsum.photos/id/905/900/600"
                  alt=""
                />
              </figure>
              <div class="ui-card-product__company">
                Az. agricola sociale di CARITAS Ticino
              </div>
              <div class="ui-card-product__title">Biocassetta di stagione</div>
              <div class="ui-card-product__subtitle">
                Cassetta peso variabile
              </div>
              <div class="ui-card-product__footer">
                <div class="ui-card-product__info">0.57 CHF/kg</div>
                <div class="ui-card-product__price">13.00 <sup>CHF</sup></div>
              </div>
            </div>
            <div class="ui-card-product">
              <figure class="ui-card-product__figure">
                <img
                  class="ui-card-product__img"
                  src="https://picsum.photos/id/906/900/600"
                  alt=""
                />
              </figure>
              <div class="ui-card-product__company">Fattoria la Colombera</div>
              <div class="ui-card-product__title">Bio yogur frutta</div>
              <div class="ui-card-product__subtitle">3 varianti</div>
              <div class="ui-card-product__footer">
                <div class="ui-card-product__info"></div>
                <div class="ui-card-product__price">
                  11.00 <sup>CHF</sup>-13.00 <sup>CHF</sup>
                </div>
              </div>
            </div>
            <div class="ui-card-product">
              <figure class="ui-card-product__figure">
                <img
                  class="ui-card-product__img"
                  src="https://picsum.photos/id/907/900/600"
                  alt=""
                />
              </figure>
              <div class="ui-card-product__company">Terrani Carni</div>
              <div class="ui-card-product__title">
                Carne macinata per bolognese manzo
              </div>
              <div class="ui-card-product__subtitle"></div>
              <div class="ui-card-product__footer">
                <div class="ui-card-product__info">minimo 1 kg</div>
                <div class="ui-card-product__price">0.57 <sup>CHF</sup>/kg</div>
              </div>
            </div>
            <div class="ui-card-product">
              <figure class="ui-card-product__figure">
                <img
                  class="ui-card-product__img"
                  src="https://picsum.photos/id/908/900/600"
                  alt=""
                />
              </figure>
              <div class="ui-card-product__company">Profumo d’Aurora</div>
              <div class="ui-card-product__title">Sapone fichi e avena</div>
              <div class="ui-card-product__subtitle">Saponetta 100gr</div>
              <div class="ui-card-product__footer">
                <div class="ui-card-product__info">0.57 CHF/kg</div>
                <div class="ui-card-product__price">4.00 <sup>CHF</sup></div>
              </div>
            </div>
          </div>
        </CCardBody>
      </CCard>
    </section>

    <section class="mb-5">
      <CButton @click="testModal = true" color="primary">
        Aggiungi foto prodotto
      </CButton>
      <CModal
        title="Aggiungi foto prodotto"
        size="md"
        centered="true"
        :show.sync="testModal"
      >
        <form
          class="dropzone"
          method="post"
          action=""
          enctype="multipart/form-data"
        >
          <div class="dropzone__input">
            <input
              class="dropzone__file"
              type="file"
              name="files[]"
              id="file"
              data-multiple-caption="{count} files selected"
              multiple
            />
            <label for="file">
              <font-awesome-icon icon="camera" />
              <br />
              Seleziona una foto
              <br />
              <span class="dropzone__dragndrop"> o trascinala qui</span>
            </label>
            <button class="dropzone__button" type="submit">Upload</button>
          </div>
          <div class="dropzone__uploading">Uploading…</div>
          <div class="dropzone__success">Done!</div>
          <div class="dropzone__error">Error! <span></span>.</div>
        </form>
        <template v-slot:footer>
          <CButton color="primary" variant="outline"> Annulla </CButton>
          <CButton color="primary"> Salva </CButton>
        </template>
      </CModal>
    </section>

    <section class="mb-5">
      <h1>Table (real)</h1>
      <CCard>
        <CCardBody>
          <CForm>
            <CRow>
              <CCol md="5" class="mb-3">
                <CInput
                  label="Cerca prodotti"
                  placeholder="es. Fagioli borlotti"
                >
                  <template #prepend-content>
                    <font-awesome-icon icon="search" />
                  </template>
                </CInput>
              </CCol>
            </CRow>
          </CForm>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th></th>
                  <th>NOME</th>
                  <th>QUANTITÀ</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>PERIODO DI VENDITA</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="ui-cell-switch">
                    <CSwitch variant="3d" size="sm" color="secondary" checked />
                  </td>
                  <td>
                    <strong>Baguette</strong>
                    <div>Integrale</div>
                  </td>
                  <td class="ui-cell-qty1">
                    <CSelect :options="['limitata', 'illimitata']" custom />
                  </td>
                  <td class="ui-cell-qty2">
                    <CInput append="pezzi" />
                  </td>
                  <td class="ui-cell-qty3">
                    <CSelect :options="['a consegna', 'altro']" custom />
                  </td>
                  <td class="ui-cell-separator"></td>
                  <td class="ui-cell-period1">
                    <CSelect :options="['tutto l\'anno', 'limitata']" custom />
                  </td>
                  <td class="ui-cell-period2">
                    <CInput type="date" disabled />
                  </td>
                  <td class="ui-cell-period3">
                    <CInput type="date" disabled />
                  </td>
                </tr>
                <tr>
                  <td class="ui-cell-switch">
                    <CSwitch variant="3d" size="sm" color="secondary" checked />
                  </td>
                  <td>
                    <strong>Baguette</strong>
                    <div>Classica</div>
                  </td>
                  <td class="ui-cell-qty1">
                    <CSelect :options="['limitata', 'illimitata']" custom />
                  </td>
                  <td class="ui-cell-qty2">
                    <CInput append="pezzi" value="3" disabled />
                  </td>
                  <td class="ui-cell-qty3">
                    <CSelect
                      :options="['a consegna', 'altro']"
                      disabled
                      custom
                    />
                  </td>
                  <td class="ui-cell-separator"></td>
                  <td class="ui-cell-period1">
                    <CSelect :options="['tutto l\'anno', 'limitata']" custom />
                  </td>
                  <td class="ui-cell-period2">
                    <CInput type="date" />
                  </td>
                  <td class="ui-cell-period3">
                    <CInput type="date" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CCardBody>
      </CCard>

      <br />

      <CCard>
        <CCardBody>
          <CForm>
            <CRow>
              <CCol md="5" class="mb-3">
                <CInput
                  label="Cerca prodotti"
                  placeholder="es. Fagioli borlotti"
                >
                  <template #prepend-content>
                    <font-awesome-icon icon="search" />
                  </template>
                </CInput>
              </CCol>
            </CRow>
          </CForm>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th></th>
                  <th>NOME</th>
                  <th>PREZZO DA INCASSARE <em>*</em></th>
                  <th>PREZZO CONSIGLIATO DI VENDITA</th>
                  <th>ALIQUOTA IVA <em>*</em></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="ui-cell-switch">
                    <CSwitch variant="3d" size="sm" color="secondary" checked />
                  </td>
                  <td>
                    <strong>Baguette</strong>
                    <div>Integrale</div>
                  </td>
                  <td class="ui-cell-price">
                    <CInput append="CHF / 1 pezzo" />
                  </td>
                  <td class="ui-cell-price">
                    <CInput append="CHF / 1 pezzo" />
                  </td>
                  <td class="ui-cell-vat">
                    <CSelect
                      :options="['Ridotta', 'Altro 1', 'Altro 2', 'Altro 3']"
                      custom
                    />
                  </td>
                </tr>
                <tr>
                  <td class="ui-cell-switch">
                    <CSwitch variant="3d" size="sm" color="secondary" checked />
                  </td>
                  <td>
                    <strong>Baguette</strong>
                    <div>Classica</div>
                  </td>
                  <td class="ui-cell-price">
                    <CInput append="CHF / 1 pezzo" />
                  </td>
                  <td class="ui-cell-price">
                    <CInput append="CHF / 1 pezzo" />
                  </td>
                  <td class="ui-cell-vat">
                    <CSelect
                      :options="['Ridotta', 'Altro 1', 'Altro 2', 'Altro 3']"
                      custom
                    />
                  </td>
                </tr>
                <tr>
                  <td class="ui-cell-switch">
                    <CSwitch variant="3d" size="sm" color="secondary" checked />
                  </td>
                  <td>
                    <strong>Baguette con nome molto lungo</strong>
                    <div>Super</div>
                  </td>
                  <td class="ui-cell-price">
                    <CInput append="CHF / 1 pezzo" />
                  </td>
                  <td class="ui-cell-price">
                    <CInput append="CHF / 1 pezzo" />
                  </td>
                  <td class="ui-cell-vat">
                    <CSelect
                      :options="['Ridotta', 'Altro 1', 'Altro 2', 'Altro 3']"
                      custom
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CCardBody>
      </CCard>
    </section>

    <section class="mb-5">
      <h1>Table (Syncfusion)</h1>
      <CCard>
        <CCardBody>
          <CForm>
            <CRow class="align-items-end">
              <CCol md="5">
                <CInput
                  label="Cerca prodotti"
                  placeholder="es. Fagioli borlotti"
                >
                  <template #prepend-content>
                    <font-awesome-icon icon="search" />
                  </template>
                </CInput>
              </CCol>
              <CCol md>
                <CSelect
                  label="Categoria"
                  :options="[
                    'Tutte',
                    'Categoria 1',
                    'Categoria 2',
                    'Categoria 3',
                  ]"
                  custom
                />
              </CCol>
              <CCol md>
                <CSelect
                  label="Conservazione"
                  :options="[
                    'Tutte',
                    'Conservazione 1',
                    'Conservazione 2',
                    'Conservazione 3',
                  ]"
                  custom
                />
              </CCol>
              <CCol md>
                <CSelect
                  label="Vendita"
                  :options="['Tutte', 'Vendita 1', 'Vendita 2', 'Vendita 3']"
                  custom
                />
              </CCol>
            </CRow>
          </CForm>

          <CRow>
            <CCol>
              <CDropdown togglerText="AZIONI MULTIPLE" color="primary">
                <CDropdownItem>Attiva</CDropdownItem>
                <CDropdownItem>Disattiva</CDropdownItem>
                <CDropdownDivider />
                <CDropdownItem>Modifica prezzo</CDropdownItem>
                <CDropdownItem>Modifica disponibilità</CDropdownItem>
              </CDropdown>
            </CCol>
          </CRow>

          <ejs-grid
            :dataSource="tableData"
            :allowSelection="true"
            :selectionSettings="selectOptions"
            :allowSorting="true"
            :allowPaging="true"
          >
            <e-columns>
              <e-column type="checkbox" width="40" textAlign="Left"></e-column>
              <e-column
                :template="imageTemplate"
                width="60"
                textAlign="Center"
              ></e-column>
              <e-column :template="nameTemplate" headerText="Nome"></e-column>
              <e-column
                :template="categoryTemplate"
                headerText="Categoria"
              ></e-column>
              <e-column
                :template="priceTemplate"
                headerText="Prezzo da incassare"
                width="240"
              ></e-column>
              <e-column
                :template="marketsTemplate"
                headerText="Mercati"
                width="180"
              ></e-column>
            </e-columns>
          </ejs-grid>
        </CCardBody>
      </CCard>
    </section>

    <section class="mb-5">
      <h1>Product</h1>
      <CCard>
        <CCardHeader>
          <div class="card-title">Descrizione del prodotto</div>
          <div class="card-subtitle">
            Inserisci le informazioni principali che saranno visibili al cliente
            finale
          </div>
        </CCardHeader>
        <CCardBody>
          <div class="form-group">
            <label>Tipologia</label>
            <CInputRadioGroup
              :options="['Alimentare', 'Non alimentare', 'Digitale', 'Servizi']"
              custom
              inline
            />
          </div>
          <CRow>
            <CCol md="8">
              <CInput
                label="Titolo"
                required
                placeholder="Inserisci il nome del tuo prodotto"
                description="lorem"
              />
            </CCol>
          </CRow>
          <div class="card-heading">Heading separator</div>
          <CRow>
            <CCol md="8">
              <CInput required>
                <template #label>
                  <label>
                    Pippo
                    <em class="text-danger">*</em>
                    <span
                      class="text-primary ml-1"
                      v-c-tooltip="{
                        placement: 'right',
                        content:
                          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia exercitationem expedita nobis fugiat ea illum praesentium, laborum magnam beatae nostrum recusandae maxime reprehenderit, quam. Sunt molestiae, doloremque similique. Asperiores, dignissimos?',
                      }"
                    >
                      <font-awesome-icon icon="question-circle" />
                    </span>
                  </label>
                </template>
              </CInput>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </section>

    <section class="mb-5">
      <h1>Typography</h1>
      <div class="ui-type-display-xl">Display xl</div>
      <div class="ui-type-display-lg">Display lg</div>
      <div class="ui-type-display-md">Display md</div>
      <div class="ui-type-display-sm">Display sm</div>
      <div class="ui-type-heading">Heading</div>
      <div class="ui-type-subheading">Subheading</div>
      <div class="ui-type-body">Body</div>
      <div class="ui-type-caption">Caption</div>
      <div class="ui-type-hint">Hint</div>
    </section>

    <section class="mb-5">
      <h1>Buttons</h1>
      <CRow class="mb-3">
        <CCol md="auto">
          <CButton color="primary"> Primary </CButton>
        </CCol>
        <CCol md="auto">
          <CButton color="secondary"> Secondary </CButton>
        </CCol>
        <CCol md="auto">
          <CButton color="primary" variant="outline"> Primary Outline </CButton>
        </CCol>
        <CCol md="auto">
          <CButton color="secondary" variant="outline">
            Secondary Outline
          </CButton>
        </CCol>
        <CCol md="auto">
          <CButton color="primary" variant="ghost"> Primary Ghost </CButton>
        </CCol>
        <CCol md="auto">
          <CButton color="secondary" variant="ghost"> Secondary Ghost </CButton>
        </CCol>
      </CRow>
      <CRow class="mb-3">
        <CCol md="auto">
          <CButton color="primary" size="sm"> Primary </CButton>
        </CCol>
        <CCol md="auto">
          <CButton color="secondary" size="sm"> Secondary </CButton>
        </CCol>
        <CCol md="auto">
          <CButton color="primary" variant="outline" size="sm">
            Primary Outline
          </CButton>
        </CCol>
        <CCol md="auto">
          <CButton color="secondary" variant="outline" size="sm">
            Secondary Outline
          </CButton>
        </CCol>
        <CCol md="auto">
          <CButton color="primary" variant="ghost" size="sm">
            Primary Ghost
          </CButton>
        </CCol>
        <CCol md="auto">
          <CButton color="secondary" variant="ghost" size="sm">
            Secondary Ghost
          </CButton>
        </CCol>
      </CRow>
    </section>

    <section class="mb-5">
      <h1>Forms</h1>
      <CRow>
        <CCol lg="4" class="mb-3">
          <CInput label="Name" placeholder="Enter your name" />
        </CCol>
        <CCol lg="4" class="mb-3">
          <CSelect
            label="Month"
            :options="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
            custom
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol lg="8" class="mb-3">
          <textarea
            class="form-control"
            rows="6"
            placeholder="Write something here..."
          ></textarea>
        </CCol>
      </CRow>
      <CRow>
        <CCol lg="4" class="mb-3">
          <template v-for="option in ['Option 1', 'Option 2', 'Option 3']">
            <CInputCheckbox
              :key="option"
              :label="option"
              :value="option"
              custom
            />
          </template>
        </CCol>
        <CCol lg="4" class="mb-3">
          <CInputRadioGroup
            :options="['Option 1', 'Option 2', 'Option 3']"
            custom
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol lg="4" class="mb-3">
          <template v-for="option in ['Option 1', 'Option 2', 'Option 3']">
            <CInputCheckbox
              :key="option"
              :label="option"
              :value="option"
              custom
              inline
            />
          </template>
        </CCol>
        <CCol lg="4" class="mb-3">
          <CInputRadioGroup
            :options="['Option 1', 'Option 2', 'Option 3']"
            custom
            inline
          />
        </CCol>
      </CRow>
    </section>

    <section class="mb-5">
      <h1>Grid</h1>
      <CRow>
        <CCol lg="4" class="mb-3">
          <div class="bg-red p-3">col-lg-4</div>
        </CCol>
        <CCol md="3" class="mb-3">
          <div class="bg-green p-3">col-md-3</div>
        </CCol>
        <CCol sm="3" class="mb-3">
          <div class="bg-blue p-3">col-sm-3</div>
        </CCol>
        <CCol sm="2" class="mb-3">
          <div class="bg-yellow p-3">col-sm-2</div>
        </CCol>
      </CRow>
    </section>

    <section class="mb-5">
      <h1>Tabs</h1>
      <CRow class="mb-3">
        <CCol md="6">
          <CCard>
            <CTabs>
              <CTab title="One">
                1. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                dolor in reprehenderit in voluptate velit esse cillum dolore eu
                fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                proident, sunt in culpa qui officia deserunt mollit anim id est
                laborum.
              </CTab>
              <CTab title="Two">
                2. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                dolor in reprehenderit in voluptate velit esse cillum dolore eu
                fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                proident, sunt in culpa qui officia deserunt mollit anim id est
                laborum.
              </CTab>
              <CTab title="Three">
                3. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                dolor in reprehenderit in voluptate velit esse cillum dolore eu
                fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                proident, sunt in culpa qui officia deserunt mollit anim id est
                laborum.
              </CTab>
              <CTab title="Four">
                4. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                dolor in reprehenderit in voluptate velit esse cillum dolore eu
                fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                proident, sunt in culpa qui officia deserunt mollit anim id est
                laborum.
              </CTab>
              <CTab title="Disabled" disabled> Text will not be shown. </CTab>
            </CTabs>
          </CCard>
        </CCol>
        <CCol md="6">
          <CCard>
            <CCardHeader> Card header </CCardHeader>
            <CCardBody>
              1. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
              do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </section>

    <section class="mb-5">
      <h1>Cards</h1>
      <CRow class="mb-3">
        <CCol md="4">
          <CCard>
            <CCardHeader> Standard usage </CCardHeader>
            <CCardBody> This content is in card body component. </CCardBody>
            <CCardFooter> Standard Footer. </CCardFooter>
          </CCard>
        </CCol>
        <CCol md="4">
          <transition name="fade">
            <CCard v-if="show">
              <CCardHeader>
                Card with header actions
                <div class="card-header-actions">
                  <CLink
                    class="card-header-action btn-minimize"
                    @click="isCollapsed = !isCollapsed"
                  >
                    <CIcon
                      :name="`cil-chevron-${isCollapsed ? 'bottom' : 'top'}`"
                    />
                  </CLink>
                  <CLink
                    href="#"
                    class="card-header-action btn-close"
                    v-on:click="show = false"
                  >
                    <CIcon name="cil-x-circle" />
                  </CLink>
                </div>
              </CCardHeader>
              <CCollapse :show="isCollapsed">
                <CCardBody>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Excepturi minus, quod illo, libero aut sint et debitis ea
                  sapiente, iste natus ipsa! Earum, quidem pariatur nisi illo
                  ratione molestias ipsa?
                </CCardBody>
              </CCollapse>
            </CCard>
          </transition>
        </CCol>
      </CRow>
    </section>
  </div>
</template>

<script>
import Vue from "vue";
import { Sort, Page } from "@syncfusion/ej2-vue-grids";

export default {
  name: "Test",
  provide: {
    grid: [Sort, Page],
  },
  data: function () {
    return {
      testModal: false,
      show: true,
      isCollapsed: true,
      selectOptions: { persistSelection: true },
      imageTemplate: function () {
        return {
          template: Vue.component("imageTemplate", {
            template:
              '<img class="border rounded" src="https://picsum.photos/36" />',
          }),
        };
      },
      nameTemplate: function () {
        return {
          template: Vue.component("nameTemplate", {
            template:
              "<div><strong>Brioches vegana senza olio di palma</strong> <div>Albicocca</div></div>",
          }),
        };
      },
      categoryTemplate: function () {
        return {
          template: Vue.component("categoryTemplate", {
            template:
              '<div><div class="ui-type-caption text-gray-600">PANE E SFORNATI</div><div>Sfornati dolci e torte</div></div>',
          }),
        };
      },
      priceTemplate: function () {
        return {
          template: Vue.component("priceTemplate", {
            template: "<strong>€3.00</strong>",
          }),
        };
      },
      marketsTemplate: function () {
        return {
          template: Vue.component("marketsTemplate", {
            template:
              '<div class="d-inline-block" v-c-tooltip="{content: `Lorem ipsum dolor`, placement: `left`}"><span class="badge badge-bubble badge-success badge-pill"></span> <span class="badge badge-bubble badge-info badge-pill"></span></div>',
          }),
        };
      },

      tableData: [
        {
          id: 1,
        },
        {
          id: 2,
        },
        {
          id: 3,
        },
        {
          id: 4,
        },
        {
          id: 4,
        },
        {
          id: 5,
        },
        {
          id: 6,
        },
        {
          id: 7,
        },
      ],
      flickityProducts: {
        pageDots: false,
        cellAlign: "left",
        groupCells: true,
      },
      flickityProduct: {
        pageDots: false,
        prevNextButtons: false,
      },
      flickityProductAsNavFor: {
        asNavFor: ".flickity-product",
        cellAlign: "left",
        contain: true,
        pageDots: false,
        prevNextButtons: false,
      },
    };
  },
};
</script>
